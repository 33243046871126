<template>
	<div>
		<vs-card actionable class="cardx">
			<div slot="header">
				<vs-row style="margin-bottom: 1%">
					<vs-col
						vs-type="flex"
						vs-justify="flex-start"
						vs-align="center"
						vs-w="2"
					>
						<h3>SPOC Dashboard</h3>
					</vs-col>
					<vs-col
						vs-type="flex"
						vs-justify="flex-start"
						vs-align="center"
						vs-w="4"
					>
						<v-select
							placeholder="Select Team"
							style="margin: 10px; z-index: 3000"
							class="w-full"
							v-model="selectedSpocTeam"
							:options="SpocTeams"
							label="text"
						></v-select>
					</vs-col>
					<vs-col
						vs-type="flex"
						vs-justify="flex-start"
						vs-align="center"
						vs-w="4"
						v-if="this.selectedSpocTeam === 'SR Dashboard'"
					>
						<v-select
							placeholder="Levels"
							style="margin-top: 9%;margin: 10px; z-index: 3000"
							class="w-full"
							v-model="LevelsSelect"
							:options="levelsOptions"
						></v-select>
					</vs-col>
					<vs-col
						vs-type="flex"
						vs-justify="flex-start"
						vs-align="center"
						vs-w="2"
					>
						<vs-button color="dark" type="filled" @click="getSRData()">GO</vs-button>
					</vs-col>
				</vs-row>
			</div>
			<vs-card actionable class="cardx" v-if="loaded">
				<vs-row type="flex" style="margin-top: 2%">
					<vs-col
						vs-order="1"
						vs-type="flex"
						vs-justify="center"
						vs-align="center"
						vs-w="6"
						style="font-size: 19px"
					>
						<b>Call Logs</b>
					</vs-col>
					<vs-col
						vs-order="1"
						vs-type="flex"
						vs-justify="center"
						vs-align="center"
						vs-w="6"
						style="font-size: 19px"
					>
						<b>Work Logs</b>
					</vs-col>
				</vs-row>
				<vs-table :data="sortSpoc">
					<template slot="thead">
						<vs-th>Spoc Name</vs-th>
						<vs-th>HF</vs-th>
						<vs-th>Missed Calls</vs-th>
						<vs-th>Untracked</vs-th>
						<vs-th>To Update</vs-th>
						<vs-th>Call Logs Today</vs-th>
						<vs-th>Untapped</vs-th>
						<vs-th>Delays</vs-th>
						<vs-th>Today</vs-th>
						<vs-th>Waiting</vs-th>
						<vs-th>MHPS</vs-th>
						<vs-th>Referrals</vs-th>
						<vs-th>Net Enquiries</vs-th>
						<vs-th>Flag</vs-th>
					</template>
					<template slot-scope="{ data }">
						<vs-tr :key="indextr" v-for="(tr, indextr) in data">
							<vs-td :data="data[indextr].full_name">
								{{ data[indextr].full_name }}
							</vs-td>
							<vs-td>
								{{ data[indextr].user_tag }}
							</vs-td>
							<vs-td :data="data[indextr].first_name">
								<a @click="getData(tr.id, 'missed_calls')">
									{{ data[indextr].missed_calls }}
								</a>
							</vs-td>
							<vs-td :data="data[indextr].first_name">
								<a @click="getData(tr.id, 'untracked')">
									{{ data[indextr].untracked }}
								</a>
							</vs-td>
							<vs-td :data="data[indextr].first_name">
								<a @click="getData(tr.id, 'to_update')">
									{{ data[indextr].to_update }}
								</a>
							</vs-td>
							<vs-td :data="data[indextr].first_name">
								<a @click="getData(tr.id, 'call_logs_today')">
									{{ data[indextr].call_logs_today }}
								</a>
							</vs-td>
							<vs-td :data="data[indextr].first_name">
								<a @click="getData(tr.id, 'untapped')">
									{{ data[indextr].untapped }}
								</a>
							</vs-td>
							<vs-td :data="data[indextr].first_name">
								<a @click="getData(tr.id, 'delays')">
									{{ data[indextr].delays }}
								</a>
							</vs-td>
							<vs-td :data="data[indextr].first_name">
								<a @click="getData(tr.id, 'today')">
									{{ data[indextr].today }}
								</a>
							</vs-td>
							<vs-td :data="data[indextr].first_name">
								<a @click="getData(tr.id, 'waiting')">
									{{ data[indextr].waiting }}
								</a>
							</vs-td>
							<vs-td :data="data[indextr].first_name">
								<a @click="getData(tr.id, 'mhps')">
									{{ data[indextr].mhps }}
								</a>
							</vs-td>
								<vs-td :data="data[indextr].first_name">
								<a @click="getData(tr.id, 'referrals')">
									{{ data[indextr].referrals }}
								</a>
							</vs-td>
							<vs-td :data="data[indextr].first_name">
								<a
									@click="
										getData(
											tr.id,
											'net_enquiries',
											data[indextr].net_enquiries_sorted
										)
									"
									>{{ data[indextr].net_enquiries_sorted }}</a
								>
							</vs-td>

							<vs-td :data="data[indextr].first_name">
								<a @click="getData(tr.id, 'flag')">{{ data[indextr].flag }}</a>
							</vs-td>
							<!-- <vs-td :data="data[indextr].first_name"
                ><a @click="getData(tr.id, 'net_enquiries')">{{
                  data[indextr].net_enquiries
                }}</a></vs-td
              >-->
						</vs-tr>
						<vs-tr v-show="hidetotal">
							<vs-td>
								{{ total.Header }}
							</vs-td>
							<vs-td>
								
							</vs-td>
							<vs-td>
								{{ total.missed_calls }}
							</vs-td>
							<vs-td>
								{{ total.untracked }}
							</vs-td>
							<vs-td>
								{{ total.to_update }}
							</vs-td>
							<vs-td>
								{{ total.call_logs_today }}
							</vs-td>
							<vs-td>
								{{ total.untapped }}
							</vs-td>
							<vs-td>
								{{ total.delays }}
							</vs-td>
							<vs-td>
								{{ total.today }}
							</vs-td>
							<vs-td>
								{{ total.waiting }}
							</vs-td>
							<vs-td>
								{{ total.mhps }}
							</vs-td>
							<vs-td>
								{{ total.referrals }}
							</vs-td>
							<vs-td>
								{{ total.net_enquiries }}
							</vs-td>
							<vs-td>
								{{ total.flag }}
							</vs-td>
						</vs-tr>
					</template>
				</vs-table>
			</vs-card>
		</vs-card>
		<SpocDashboardCallLogPopup></SpocDashboardCallLogPopup>
		<SpocDashboardAdminToUpdate></SpocDashboardAdminToUpdate>
		<SpocDashboardAdminWorkLog></SpocDashboardAdminWorkLog>
		<SpocDashboardCmNetEnquiries></SpocDashboardCmNetEnquiries>
		<!-- <SpocDashboardNetEnquiries></SpocDashboardNetEnquiries> -->
	</div>
</template>

<script>
import vSelect from "vue-select";
import axios from "axios";
import constants from "../../constants.json";
import SpocDashboardCallLogPopup from "../components/pagesComponents/SpocDashboardAdminCallLog.vue";
import SpocDashboardAdminToUpdate from "../components/pagesComponents/SpocDashboardAdminToUpdate.vue";
import SpocDashboardAdminWorkLog from "../components/pagesComponents/SpocDashboardAdminWorkLog.vue";
// import SpocDashboardNetEnquiries from "../components/pagesComponents/SpocDashboardNetEnquiries.vue";
import SpocDashboardCmNetEnquiries from "../components/pagesComponents/SpocDashboardCMNetenquiries.vue";
// import SpocDashboardNetEnquiries from "../components/pagesComponents/SpocDashboardNetEnquiries.vue";
import eventbus from "../components/eventbus";
export default {
	mounted() {
		this.spocscopy = [];
		this.cities = [];
		this.spocs = [];
		this.selectedSpocTeam = "";
		// if (this.selectedSpocTeam === "SR Dashboard") {
		// 	this.getSpocs("getSRSpocs");
		// }
	},
	data() {
		return {
			hidetotal: false,
			loaded: false,
			spocscopy: [],
			cities: [],
			sortSpoc: [],
			rawBde: [],
			spocs: [],
			selected_spoc: "",
			selected_city: "",
			cityString: "",
			EnquirySpelling: "Enquiries",
			checkedEnquiries: [],
			SpocId: "",
			SpocName: "",
			MissedCall: "",
			UntrackedCall: "",
			ToUpdate: "",
			CallLogToday: "",
			UntappedData: "",
			DelaysData: "",
			TodayData: "",
			WaitingData: "",
			MhpsData: "",
			NetEnquiries: "",
			total: {
				missed_calls: [],
				untracked: [],
				to_update: [],
				call_logs_today: [],
				untapped: [],
				delays: [],
				today: [],
				waiting: [],
				net_enquiries: [],
				referrals:[],
				mhps: [],
				flag: [],
			},
			selectedSpocTeam: "",
			SpocTeams: [
				"SR Dashboard",
				"SR Collection Dashboard",
				"SR Corporates Dashboard",
				"SR Alumni Dashboard",
				"SR Warehouse Dashboard",
				"GM Dashboard",
				// "Collection Dashboard",
				
				// "Acads Dashboard",
			],
			sr_sub_team: "SR",
			gm_team: "",
			collection_team: "",
			corporate_team: "",
			acads_team: "",
			LevelsSelect: "All",
            levelsOptions: [
                "All",
                "Onboarding",
                "SR-M7",
                "Non M7",
                "SR-M8,M9,M10",
                // "SR-M9",
                // "SR-M10"
            ],
		};
	},
	components: {
		"v-select": vSelect,
		SpocDashboardCallLogPopup,
		SpocDashboardAdminToUpdate,
		SpocDashboardAdminWorkLog,
		// SpocDashboardNetEnquiries,
		SpocDashboardCmNetEnquiries,
	},
	watch: {
		// selectedSpocTeam(val) {
		// 	if (val === "SR Dashboard") {
				
		// 	} 
		// },
	},
	methods: {
		getSRData() {
			if (this.selectedSpocTeam === "SR Dashboard") {
				this.getSpocs("getSRSpocs");		
				this.sr_sub_team = "SR";
			} else if (this.selectedSpocTeam === "GM Dashboard") {
				this.getSpocs("getGMSpocs");
				this.gm_team = "GM"
			} else if (this.selectedSpocTeam === "SR Collection Dashboard") {
				this.getSpocs("getSRSpocs");
				this.sr_sub_team = "Collection";
			} else if (this.selectedSpocTeam === "SR Alumni Dashboard") {
				this.getSpocs("getSRSpocs");
				this.sr_sub_team = "Alumni SR";
			} else if (this.selectedSpocTeam === "SR Warehouse Dashboard") {
				this.getSpocs("getSRSpocs");
				this.sr_sub_team = "Warehouse";
			} else if (this.selectedSpocTeam === "Collection Dashboard") {
				this.getSpocs("getCollectionSpocs");
				this.collection_team = "Collections";
			} else if (this.selectedSpocTeam === "SR Corporates Dashboard") {
				this.getSpocs("getSRSpocs");
				this.sr_sub_team = "Corporate Relations";
			} else if (this.selectedSpocTeam === "Acads Dashboard") {
				this.getSpocs("getAcads");
				this.acads_team = "Acads";
			}
		},
		removeUnwwantSpocs(SpocList) {
			let wantedSpocIds = [
				// 178,
				// 179,
				// 181,
				// 182,
				// 186,
				// 187,
				// 188,
				// 189,
				// 190,
				// 200,
				// 56,
				// 111,
				// 69,
				// 192,
				// 84,
				// 83,
				// 191,
				// 193,
				// 43,
				// 103,
				// 135,
				// 133,
				// 134,
				// 89,
				// 94,
				// 54,
				// 195,
				// 76,
				// 75,
				// 100,
				// 180,
				// 96,
				// 176,
				// 174,
				// 194,
				// 196,
				// 99,
				// 46,
				// 197,
				// 80
			];
			let filteredSpocs = SpocList.filter(function (el) {
				return wantedSpocIds.indexOf(el.id) <= 0;
			});
			return filteredSpocs;
		},
		NetEnquiryaggregator(payload) {
			// console.log(payload);
			// return;
			payload.forEach((data_point) => {
				data_point.mobile = data_point.mobile.split(",");
				data_point.dialingNumber = "";
				if (data_point.mobile.length > 0) {
					data_point.dialingNumber = this.getLastTenDigits(
						data_point.mobile[0]
					);
				}
			});
			var unique = payload
				.map((lead) => {
					return {
						count: 1,
						name: lead.name,
						dialingNumber: lead.dialingNumber,
					};
				})
				.reduce((a, b) => {
					a[b.dialingNumber] = (a[b.dialingNumber] || 0) + b.count;
					return a;
				}, {});
			let mainObj = {};
			payload.forEach((lead) => {
				for (var key in unique) {
					if (unique.hasOwnProperty(key)) {
						if (lead.dialingNumber === key) {
							lead.count = unique[key];
						}
					}
				}
			});
			for (var key in unique) {
				if (unique.hasOwnProperty(key)) {
					mainObj[key] = [];
					payload.forEach((net_enquiry) => {
						if (net_enquiry.dialingNumber === key) {
							mainObj[key].push(net_enquiry);
						}
					});
				}
			}
			let newPayload = [];
			for (var parameter in mainObj) {
				if (mainObj.hasOwnProperty(parameter)) {
					mainObj[parameter];
					newPayload.push(mainObj[parameter][0]);
				}
			}
			return newPayload;
			// console.log(newPayload.length);
		},
		getData(spoc_id, category) {
			console.log(spoc_id, category);
			let spocName = null;
			this.SpocName.forEach((spoc) => {
				if (spoc.id === spoc_id) {
					spocName = spoc.full_name;
				}
			});
			let url = "";
			if (this.selectedSpocTeam === "SR Dashboard") { 
				if (this.LevelsSelect === "All") {
					url = `${constants.ADMIN_SERVER_API}getSrManagerDashboardData?type=all`;
				}
				if (this.LevelsSelect === "Onboarding") {
					url = `${constants.ADMIN_SERVER_API}getSrManagerDashboardData?type=onboard`;
				}
				if (this.LevelsSelect === "SR-M7") {
					url = `${constants.ADMIN_SERVER_API}getSrManagerDashboardData?type=mseven`;
				}
				if (this.LevelsSelect === "Non M7") {
					url = `${constants.ADMIN_SERVER_API}getSrManagerDashboardData?type=below_m7`;
				}
				if (this.LevelsSelect === "SR-M8,M9,M10") {
					url = `${constants.ADMIN_SERVER_API}getSrManagerDashboardData?type=above_m7`;
				}
				// url = `${constants.ADMIN_SERVER_API}getSRDashboardForAdmin?spoc_id=${spoccount}?type="${this.LevelsSelect}"`;
			} else {
				url = `${constants.ADMIN_SERVER_API}getSRDashboardForAdminDetails`;
			}
			let obj = {
				spoc_id: spoc_id,
				category: category,
			};
			axios
				.get(url, {
					params: obj,
					headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
				})
				.then((response) => {
					console.log(response);
					this.response = response;
					if (category === "net_enquiries") {
						eventbus.$emit(
							"spocDashboardAdminCM-NetEnquiries",
							response.data,
							url,
							obj,
							category,
							spocName
						);
					}
					if (category === "missed_calls" || category === "untracked") {
						eventbus.$emit(
							"spocDashboardAdmin-calllog",
							response.data,
							url,
							obj,
							category,
							spocName
						);
					} else if (category === "to_update" || category === "call_logs_today") {
						eventbus.$emit(
							"spocDashboardAdmin-toUpdate",
							response.data,
							url,
							obj,
							category,
							spocName
						);
					} else if (
						category === "delays" ||
						category === "today" ||
						category === "waiting" ||
						category === "mhps" ||
						category === "referrals" ||
						category === "untapped" ||
						category === "flag" 
					) {
						this.$store.commit("MOUNT_MWB_LEADS", response.data.data);
						eventbus.$emit("spocDashboardAdmin-WorkLog", [
							response,
							url,
							obj,
							spocName,
						]);
					}
					// else if (category === "net_enquiries") {
					//   this.$store.commit("MOUNT_MWB_LEADS", response.data.data);
					//   eventbus.$emit("spocDashboardAdminCM-NetEnquiries", [
					//     response,
					//     url,
					//     obj,
					//   ]);
					// }
				})
				.catch((error) => {
					this.handleError(error);
				});
		},
		getSpocs(endPoint) {
			console.log(endPoint)
			let spocArr = [];
			
			
			let	url = `${constants.ADMIN_SERVER_API}` + `${endPoint}`;
			
			axios
				.get(url, {
					headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
				})
				.then((response) => {
                    this.sortSpoc = []
                    this.total = []
					this.loggedin = response.data.logged_in_user_id;
					console.log("lenght", response.data.spocs.length);
                    if (response.data.spocs.length === 0) {
						this.$vs.loading.close();
						this.$vs.notify({
							title: "No spocs for this team",
							text: response.data.message,
							color: "danger",
						});
						// this.Sp ocName = [];
					} else {
					response.data.spocs = this.removeUnwwantSpocs(response.data.spocs);
					response.data.spocs.forEach(element => {
						console.log("element team", element.team)
						if(element.sub_team === this.sr_sub_team && element.team !== this.gm_team) {
							spocArr.push(element);
							this.SpocName = spocArr;
						} else if(element.team === this.gm_team && element.sub_team !== this.sr_sub_team) {
							spocArr.push(element);
							this.SpocName = spocArr;
						} 
					});
					this.assignLead(this.SpocName);
					
                    }
					
				})
				.catch((error) => {
					this.handleError(error);
				});
		},
		async assignLead(spoc) {
			this.$vs.loading();
			this.loaded = false;
			for (let i = 0; i < spoc.length; i++) {
				
				let response = await this.getDashbaordStatsSpoc(spoc[i].id);
				spoc[i].missed_calls = response.data.missed_calls;
				spoc[i].untracked = response.data.untracked;
				spoc[i].to_update = response.data.to_update;
				spoc[i].call_logs_today = response.data.call_logs_today;
				spoc[i].untapped = response.data.untapped;
				spoc[i].delays = response.data.delays;
				spoc[i].today = response.data.today;
				spoc[i].waiting = response.data.waiting;
				spoc[i].mhps = response.data.mhps;
				spoc[i].referrals = response.data.referrals;
				spoc[i].net_enquiries = response.data.net_enquiries;
				spoc[i].flag = response.data.flag;
				spoc[i].net_enquiries_sorted = response.data.net_enquiries;
			}
			this.$vs.loading.close();
			this.sortSpoc = spoc;
			this.totalCalculate(spoc);
		},
		getDashbaordStatsSpoc(spoccount) {
			// let SpocId = spoccount;
			let url = "";
			if (this.selectedSpocTeam === "SR Dashboard") { 
				if (this.LevelsSelect === "All") {
					url = `${constants.ADMIN_SERVER_API}getSrManagerDataWithLevels?spoc_id=${spoccount}&level_type=all`;
				}
				if (this.LevelsSelect === "Onboarding") {
					url = `${constants.ADMIN_SERVER_API}getSRManagerDashBoardOnboardingData?spoc_id=${spoccount}`;
				}
				if (this.LevelsSelect === "SR-M7") {
					url = `${constants.ADMIN_SERVER_API}getSRManagerSevenData?spoc_id=${spoccount}`;
				}
				if (this.LevelsSelect === "Non M7") {
					url = `${constants.ADMIN_SERVER_API}getSrManagerDataWithLevels?spoc_id=${spoccount}&level_type=below_m7`;
				}
				if (this.LevelsSelect === "SR-M8,M9,M10") {
					url = `${constants.ADMIN_SERVER_API}getSrManagerDataWithLevels?spoc_id=${spoccount}&level_type=above_m7`;
				}
				// url = `${constants.ADMIN_SERVER_API}getSRDashboardForAdmin?spoc_id=${spoccount}?type="${this.LevelsSelect}"`;
			} else {
				url = `${constants.ADMIN_SERVER_API}getSRDashboardForAdmin?spoc_id=${spoccount}`;
			}
			
			const promise = new Promise((resolve, reject) => {
				axios
					.get(url, {
						headers: {
							Authorization: `Bearer ${localStorage.userAccessToken}`,
						},
					})
					.then((response) => {
						resolve(response);
					})
					.catch((error) => {
						console.log(error);
						reject();
					});
			});
			return promise;
		},
		totalCalculate(Spocs) {
			this.total.Header = "TOTAL";
			this.total.missed_calls = Spocs.reduce(
				(n, { missed_calls }) => n + missed_calls,
				0
			);
			this.total.untracked = Spocs.reduce(
				(n, { untracked }) => n + untracked,
				0
			);
			this.total.to_update = Spocs.reduce(
				(n, { to_update }) => n + to_update,
				0
			);
			this.total.call_logs_today = Spocs.reduce(
				(n, { call_logs_today }) => n + call_logs_today,
				0
			);
			this.total.untapped = Spocs.reduce((n, { untapped }) => n + untapped, 0);
			this.total.delays = Spocs.reduce((n, { delays }) => n + delays, 0);
			this.total.today = Spocs.reduce((n, { today }) => n + today, 0);
			this.total.waiting = Spocs.reduce((n, { waiting }) => n + waiting, 0);
			this.total.mhps = Spocs.reduce((n, { mhps }) => n + mhps, 0);
			this.total.referrals = Spocs.reduce((n, { referrals }) => n + referrals, 0);
			this.total.net_enquiries = Spocs.reduce(
				(n, { net_enquiries_sorted }) => n + net_enquiries_sorted,
				0
			);
			this.total.flag = Spocs.reduce((n, { flag }) => n + flag, 0);
			console.log("this.total", this.total);
			this.hidetotal = true;
			this.loaded = true;
			this.$vs.loading.close();
		},
	},
};
</script>

<style>
.vhd-input[data-v-25b15922] {
	min-width: 250px;
	padding: 5px;
	border: 1px solid #eee;
	color: #505050;
	font-size: 16px;
	line-height: 9px;
	border-radius: 8px;
	border-color: lightgray;
	outline: none;
}
.vs--searchable .vs__dropdown-toggle {
	height: 38px;
	font-size: 15px;
}
.vs--single .vs__selected .vs__dropdown-menu {
	font-size: 15px;
}
.vs-table--tbody-table .tr-values td {
	font-size: 13px;
}
</style>